'use strict';

const _ = require('lodash');

const promotionFeatureFlagNames = [
    'canWriteParkingLotPromotion',
    'canEditParkingLotPromotion',
    'canCreatePromotion',
    'canCreateSplitPromotion',
    'canDeletePromotion',
    'canDeleteParkingLotPromotion',
    'canEditInFlightPromotion',
    'canEditPromotionAssignedToSubmittedResource',
    'canUnlockPromotion',
    'canRequestUnlockPromotion',
    'canCreateStoreWidePromotion',
    'canCreateCategoryWidePromotion',
    'canReadPromotion',
    'canEditApprovedPromotion',
    'canManuallyExecutePromotion',
    'canManuallyValidatePromotion',
];

const channelFeatureFlagNames = ['canEditChannel'];

// Used to match keys in toggle-logic with feature flag checks in the code

const featureFlagNames = [
    'explicitlyAllow',
    'noRestriction',
    'apportioningFunction',
    'calculateMarginFunction',
    'calculateFundingFunctions',
    'supplierCommitmentsFilterFunction',
    'supplierCommitmentsAccessFunction',
    'canEditCampaign',
    'canEditSubCampaign',
    'canEditScenario',
    'canCreateScenario',
    'canReadScenario',
    'canEditPromotion',
    'canDeleteScenario',
    'canAccessPlanning',
    'canAccessPreparation',
    'canAccessLanding',
    'canAccessSupplierPage',
    'canAccessReporting',
    'canAccessProductsPage',
    'canAccessProxyProducts',
    'canAccessProductGroups',
    'disablePromotionEditingDaysBeforeStart',
    'disablePromotionEditingWhenAssignedToPromoResource',
    'showJsonExportButton',
    'disablePromotionEditingWhenParentNotApproved',
    'disablePromotionEditingOnWorkflowStates',
    'disablePromotionEditingOnResourceWorkflowStates',
    'disablePromoResourceEditingOnWorkflowStates',
    'promotionAllocationFilterFunction',
    'enablePublicPrivateEntities',
    'enableProductLevelSellInPeriod',
    'enableSplitPromotions',
    'canAccessAllCategories',
    'canAccessAllStoreGroups',
    'canAccessPromotionsWithoutStoreGroups',
    'canAccessPrivateCampaigns',
    'canAccessPrivateSubCampaigns',
    'canAccessPrivateScenarios',
    'canPublishCampaigns',
    'canPublishSubCampaigns',
    'canPublishScenarios',
    'dateValidatorFunction',
    'numberFilterFunction',
    'channelsStoresValidatorFunction',
    'mechanicValidatorFunction',
    'productsValidatorFunction',
    'supplyValidatorFunction',
    'offerValidatorFunction',
    'subCampaignOrdering',
    'supplierValidatorFunction',
    'numberOfEffectivenessRatingColours',
    'disableCampaignCreationDaysBeforeToday',
    'disableCampaignEditingDaysBeforeStart',
    'disableSubCampaignEditingDaysBeforeStart',
    'disableScenarioEditingDaysBeforeStart',
    'disablePromoResourceEditingDaysBeforeSubCampaignStart',
    'stateIconsMap',
    'workflowConfirmationFields',
    'workflowAlertGrouping',
    'firstWeekCalendarViewRestriction',
    'lastWeekCalendarViewRestriction',
    'supplierCommitmentOptions',
    'canAccessRateCards',
    'canAccessParkingLot',
    'canAccessAdminArea',
    'hiddenParkingLotColumns',
    'enableParentChildModule',
    'enablePromotionPermalink',
    'canEditBookmarks',
    'promotionNameIsRequired',
    'rankTabPriceField',
    'rankTabColumnsConfig',
    'weightOptions',
    'canEditListPrice',
    'canAccessClientSystemsTesting',
    'allVariantsFlag',
    'useClientStateFilter',
    'enableOfferCommercialField',
    'promotionAccessFunction',
    'autoOpenSupplierCommitmentDialogInFunding',
    'canViewScenariosFrontEnd',
    'autoReforecastPromotions',
    'hideProductsWithCategoriesUserHasNoAccessTo',
    'forbidToDragProductsWithCategoriesUserHasNoAccessTo',
    'deletePromotionFromExecutionSystem',
    'restrictAccessByStoreGroups',
    'restrictAccessByCategories',
    'enableDownloadCentre',
    'reportingOnly',
    'reportFilters',
    'productFilterExcludedFields',
    'excludedAttributes',
    'defaultAttributes',
    'showOnlyMinPrices',
    'defaultRoute',
    'defaultAdminTab',
    'canAccessUserDocs',
    'useNewCostStructure',
    'showSingleTrip',
    'allowZeroRateCardAmount',
    'allowApplyAllNotifications',
    'allowApplyAllNotificationsDialog',
    'allowCategoryWidePromotions',
    'allowStoreWidePromotions',
    'allowValidationSeverities',
    'pagination',
    ...promotionFeatureFlagNames,
    ...channelFeatureFlagNames,
];

const featureFlags = _.zipObject(featureFlagNames, featureFlagNames);

module.exports = featureFlags;
