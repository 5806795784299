const columnTypes = {
    numericColumnCustom: {
        filter: 'agNumberColumnFilter',
        cellClass: ['right-align'],
    },
    numericColumnCustomHighlightNegative: {
        filter: 'agNumberColumnFilter',
        cellClass: ['right-align'],
        cellClassRules: {
            'font-colour--red': params => params.value < 0,
        },
    },
    requiredField: {
        cellClassRules: {
            'required-field': params => !params.value,
        },
    },
};
const agGridDateComparator = (filterDateAtMidnight, cellValue) => {
    const cellDate = new Date(cellValue);
    if (cellDate < filterDateAtMidnight) return -1;

    if (cellDate > filterDateAtMidnight) return 1;

    return 0;
};

export default {
    columnTypes,
    agGridDateComparator,
};
