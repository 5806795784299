<template>
    <div class="offer-tab">
        <offer-mechanic
            :vuex-module="vuexModule"
            :namespace="computedNamespace"
            :context="context"
            :form-ref="formRef"
            @create-pog="newOfferProductGroup"
            @delete-pog="deletePOG"
        />
        <v-expansion-panels v-if="isOnOfferTab" v-model="panelIndex" flat accordion>
            <offer-group
                v-for="(offerGroup, ix) in productOfferGroups"
                :key="`${promotion._id}-${offerGroup._id}`"
                :index="ix"
                :vuex-module="vuexModule"
                :namespace="computedNamespace"
                :product-offer-group="offerGroup"
                :expanded="panelIndex === ix"
                :context="context"
                @delete="deletePOG"
                @removed="handleProductRemoval"
            />
        </v-expansion-panels>
        <create-new-button
            class="offer-tab__new-group"
            :btn-text="$tkey('newPOG')"
            :disabled="splitPromotion"
            @createNew="newOfferProductGroup"
        />
    </div>
</template>

<script>
import { get, maxBy, isEmpty, uniqBy, pick, flatten } from 'lodash';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import UXEvents from '@enums/ux-events';
import namespaces from '@enums/namespaces';
import pogScope from '@enums/product-offer-group-scope';
import { offer } from '@enums/promotion-tabs';
import { isStoreWidePromotion, isCategoryWidePromotion } from '@sharedModules/promotion-utils';
import vuexFormMixin from '@/js/mixins/vuex-form';
import { toSentenceCase } from '@/js/utils/string-utils';

const OFFER_MECHANIC_FIELD = 'offerMechanic';
const OFFER_GROUPS_FIELD = 'productOfferGroups';
const SPLIT_PROMO_FIELD = 'splitPromotion';

export default {
    mixins: [vuexFormMixin],
    localizationKey: 'planning.promotionsMaintenance.offer',

    props: {
        formRef: {
            type: Object,
            required: false,
        },
        cacheDom: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            panelIndex: 0,
        };
    },

    events: {
        onParkingLotPromoSaveFailed() {
            this.panelIndex = 0;
        },
    },

    computed: {
        ...mapGetters('promotions', ['selectedPromotion', 'getStagingAreaPromotionById']),
        ...mapGetters('context', ['userCategories']),
        ...mapState('promotions', ['changesetApplyInProgress', 'selectedPromotionMaintenanceTab']),

        isDisabled() {
            return !!this.changesetApplyInProgress[this.namespace];
        },

        isOnOfferTab() {
            return this.cacheDom || this.selectedPromotionMaintenanceTab === offer;
        },

        promotion() {
            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            return this.getStagingAreaPromotionById(
                get(this.selectedPromotion, '_id', null) || 'default'
            );
        },

        productOfferGroups() {
            return get(this.promotion, OFFER_GROUPS_FIELD, []);
        },

        splitPromotion() {
            return get(this.promotion, SPLIT_PROMO_FIELD, false);
        },

        productsOnPromotion() {
            return new Set(this.promotion.products.map(p => p.productKey));
        },
    },

    methods: {
        ...mapActions('promotions', [
            'setStagingAreaField',
            'setStagingAreaFields',
            'removeProductsFromPOG',
        ]),

        ...mapMutations('promotions', {
            mutateStagingAreaField: 'setStagingAreaField',
            setUnsavedPromotion: 'setUnsavedPromotion',
        }),

        newProductOfferGroupDescription({ buffer = 0 }) {
            const productOfferGroupDescriptions = new Set(
                this.productOfferGroups.map(({ description }) => description)
            );

            // Create a list of all product offer groups (including an empty object
            // which is a placeholder for the new product offer group being added).
            const productOfferGroupsWithPlaceholder = [...this.productOfferGroups, {}];

            const potentialProductOfferGroupDescriptions = productOfferGroupsWithPlaceholder.map(
                (pog, i) => {
                    return `${this.$tkey('offerGroup.group')} ${toSentenceCase(
                        String.fromCharCode(97 + buffer + i)
                    )}`;
                }
            );

            return potentialProductOfferGroupDescriptions.find(
                productOfferGroupDescription =>
                    !productOfferGroupDescriptions.has(productOfferGroupDescription)
            );
        },

        newOfferProductGroup({ numberOfProductOfferGroupsToCreate = 1 }) {
            // Select category by default when creating a POG for the user with access to one category
            const userSelectedCategories =
                this.userCategories.length === 1 ? this.userCategories : [];

            const updates = [];
            const currentProductOfferGroups = this.productOfferGroups;
            const newProductOfferGroups = Array(numberOfProductOfferGroupsToCreate || 1)
                .fill()
                .map((_, index) => {
                    return {
                        _id: get(maxBy(currentProductOfferGroups, '_id'), '_id', 1) + (index + 1),
                        description: this.newProductOfferGroupDescription({ buffer: index }),
                        products: [],
                        userSelectedCategories,
                        scope: pogScope.selectedProducts,
                    };
                });

            updates.push({
                fieldName: OFFER_GROUPS_FIELD,
                value: [...currentProductOfferGroups, ...newProductOfferGroups],
            });

            const offerMechanicTiers = this.promotion.offerMechanic.tiers;

            offerMechanicTiers.forEach(tier => {
                newProductOfferGroups.forEach(pog => {
                    tier.productOfferGroups.push({
                        _id: pog._id,
                        description: pog.description,
                        isOptionalOfferGroup: false,
                        rewards: [],
                        requirements: [],
                    });
                });
            });

            updates.push({
                fieldPath: OFFER_MECHANIC_FIELD,
                fieldName: 'tiers',
                value: offerMechanicTiers,
            });

            this.setStagingAreaFields({
                namespace: this.computedNamespace,
                fieldsToUpdate: updates,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
        },

        async deletePOG({ productOfferGroupId, skipOMUpdates = false }) {
            const productsInPogs = this.productsOnProductOfferGroups(this.productOfferGroups);

            const updatedPOGS = this.productOfferGroups.filter(
                pog => pog._id !== productOfferGroupId
            );

            if (!skipOMUpdates) {
                const updatedMechanic = {
                    ...this.promotion[OFFER_MECHANIC_FIELD],
                    tiers: this.promotion[OFFER_MECHANIC_FIELD].tiers.map(tier => {
                        return {
                            ...tier,
                            productOfferGroups: tier.productOfferGroups.filter(
                                pog => pog._id !== productOfferGroupId
                            ),
                        };
                    }),
                };
                // update offerMechanic pogs by tier
                await this.setStagingAreaField({
                    namespace: this.computedNamespace,
                    fieldName: OFFER_MECHANIC_FIELD,
                    value: updatedMechanic,
                });
                this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
            }

            const userSelectedCategories = uniqBy(
                flatten(
                    updatedPOGS.map(pog =>
                        pog.userSelectedCategories.map(cat =>
                            pick(cat, ['level', 'levelEntryKey', 'levelEntryDescription'])
                        )
                    )
                ),
                'levelEntryKey'
            );

            const fieldsToUpdate = [
                {
                    fieldName: 'userSelectedCategories',
                    value: isStoreWidePromotion({ productOfferGroups: updatedPOGS })
                        ? []
                        : userSelectedCategories,
                },
                {
                    fieldName: OFFER_GROUPS_FIELD,
                    value: updatedPOGS,
                },
                ...(isCategoryWidePromotion({ productOfferGroups: updatedPOGS })
                    ? [
                          {
                              fieldName: 'categories',
                              value: userSelectedCategories.map(c => c.levelEntryKey),
                          },
                      ]
                    : []),
            ];

            await this.setStagingAreaFields({
                namespace: this.computedNamespace,
                fieldsToUpdate,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });

            const productsInUpdatedPogs = this.productsOnProductOfferGroups(updatedPOGS);

            const removedProducts = new Set(
                Array.from(productsInPogs).filter(pKey => !productsInUpdatedPogs.has(pKey))
            );

            if (!isEmpty(removedProducts)) {
                await this.removeProductsFromPromotion(removedProducts);
            }

            this.globalEmit('reset-product-offer-groups-on-global-selections');
        },

        async removeProductsFromPromotion(removedProducts, productOfferGroupId) {
            const removedProductsSet = new Set(removedProducts);
            const updatedProducts = this.getStagingAreaPromotionById(
                this.promotion._id || 'default'
            ).products.filter(p => !removedProductsSet.has(p.productKey));

            await this.setStagingAreaField({
                namespace: this.computedNamespace,
                fieldName: 'products',
                value: updatedProducts,
            });

            await this.removeProductsFromPOG({
                namespace: this.computedNamespace,
                productsToRemove: removedProducts,
                productOfferGroupId,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });

            if (this.computedNamespace !== namespaces.default) {
                if (isEmpty(updatedProducts)) {
                    const isValid = this.$store.state[this.vuexModule].validationStates[
                        this.computedNamespace
                    ].isValid;
                    if (isValid) {
                        this.globalEmit(UXEvents.lastCandidateRemovedFromPromotion, {
                            namespace: this.computedNamespace,
                        });
                    }
                } else {
                    this.globalEmit(UXEvents.candidateRemovedFromPromotion, {
                        namespace: this.computedNamespace,
                        savePromotion: false,
                    });
                }
            }
        },

        async handleProductRemoval({ removedProducts, productOfferGroupId }) {
            if (!isEmpty(removedProducts)) {
                await this.removeProductsFromPromotion(removedProducts, productOfferGroupId);
            }
        },

        productsOnProductOfferGroups(pogs) {
            return pogs.reduce((acc, pog) => {
                pog.products.forEach(pKey => {
                    acc.add(pKey);
                });
                return acc;
            }, new Set());
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.offer-tab {
    &__new-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    &__changes-buttons {
        display: flex;
        align-items: center;
        justify-content: end;
        column-gap: 1rem;
        padding: 1rem;

        .v-btn {
            &.v-size--default {
                height: 2rem !important;
            }
        }
    }
}

.v-expansion-panels {
    justify-content: left !important;
    display: unset !important;
}

::v-deep {
    .toggle.rtls-toggle.v-item-group.orange-toggle {
        margin-left: 6.6rem;

        .v-btn {
            border-color: $promo-orange-dark !important;
        }

        .v-btn__content {
            color: $promo-orange-dark !important;
        }

        .v-item--active {
            background: $promo-orange-dark !important;
            .v-btn__content {
                color: $promo-white !important;
            }
        }

        &:hover {
            .v-btn__content {
                color: $promo-orange-dark !important;
            }

            .v-item--active {
                .v-btn__content {
                    color: $promo-white !important;
                }
            }
        }
    }
}
</style>
