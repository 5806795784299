import { camelCase } from 'lodash';

/**
 * Utility functions for working with strings.
 *
 * @module client/js/utils/string-utils
 */

/**
 * Converts a phrase to use sentence case (only the first letter of the first word is capitalised).
 * E.g. 'The quick brown fox jumps over the lazy dog'
 *
 * @param {string} phrase The phrase to be converted to sentence case.
 */
export function toSentenceCase(phrase) {
    if (!phrase) return phrase;

    const firstLetter = phrase.charAt(0);
    const remainingLetters = phrase.slice(1);

    return `${firstLetter.toUpperCase()}${remainingLetters}`;
}

/**
 * Converts a phrase to use all uppercase case (all the letters of all the words are capitalised).
 * E.g. 'THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG'
 *
 * @param {string} phrase The phrase to be converted to sentence case.
 */
export function toAllUppercaseCase(phrase) {
    if (!phrase) return phrase;

    return phrase.toUpperCase();
}

/**
 * Appends a colon (:) onto the end of the phrase.
 *
 * @param {string} phrase The phrase to append.
 */
export function suffixWithColon(phrase) {
    if (!phrase) return phrase;

    return `${phrase}:`;
}

/**
 * Converts a phrase to camelCase
 */
export function toCamelCase(phrase) {
    if (!phrase) return phrase;
    return camelCase(phrase);
}
