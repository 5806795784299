<template>
    <div :class="['promotion-viewer', selectedFadedClass, ghostFadedClass]">
        <!--
            Each row in the grid is made up of cells - one for each column in the grid.
            For each cell, extract the data from the promotion row, either as plain text
            or as a component to be rendered.
        -->
        <div
            v-for="(item, colIndex) in fields"
            :key="`field::${promotionNamespace}::${colIndex}`"
            :ref="`promotion_${promotionNamespace}`"
            class="promotion-viewer__grid-cell promotion-viewer__grid-cell--main-content"
            :class="[item.cellClasses, item.contentClasses]"
            @click="onPromotionClick(promotionNamespace)"
        >
            <div
                class="promotion-viewer__data"
                :class="[
                    item.dataClasses,
                    { 'promotion-viewer__data--expanded': isSelected },
                    getColourClass,
                ]"
            >
                <div v-if="item.component">
                    <component
                        :is="item.component.name"
                        v-bind="
                            constructVBindObj({
                                context: currentPromotion,
                                field: item.field,
                                ...item.component,
                            })
                        "
                        :make-read-only="promotionEditingState.disabled"
                        :read-only-reason="promotionEditingState.reason"
                    />
                </div>
                <div v-else :class="[item.fieldClasses]">
                    <span v-if="item.isCurrency">
                        {{ getPromotionField(currentPromotion, item.field) | totalMargin }}
                    </span>
                    <span v-else>
                        {{ getPromotionField(currentPromotion, item.field) }}
                    </span>
                </div>
            </div>
        </div>
        <div
            v-if="isAlwaysExpanded || isSelected"
            class="promotion-viewer--first-item promotion-viewer__extra-options extra-options__tag-selector"
            :class="[{ 'expanded-promotion': isAlwaysExpanded || isSelected }]"
        >
            <promotion-viewer-tag-selector-wrapper
                :promotion-id="promotionNamespace"
                :selected-promotion-tags="promotion.tags"
                :sub-campaign-tags="tagsOptions"
                :disabled="promotionEditingState.disabled"
            />
            <unsaved-banner style="flex-grow: 9" :promotion-id="promotionNamespace" />
        </div>
        <div
            v-if="isAlwaysExpanded || isSelected"
            :key="'promotion-maintenance-' + promotionNamespace"
            class="promotion-viewer__maintenance-pane promotion-viewer--first-item"
        >
            <promotion-maintenance-form-wrapper
                v-if="showPromoViewer"
                ref="promotionMaintenanceWrapper"
                :promotion="updatedPromotion"
                :scenario="scenario"
                :sub-campaign="subCampaign"
                :is-parking-lot="isParkingLot"
                :is-sub-campaign-allocation="isSubCampaignAllocation"
                :is-past-promotions="isPastPromotions"
                :make-read-only="promotionEditingState.disabled"
                :read-only-reason="promotionEditingState.reason"
                :promotion-editing-state="promotionEditingState"
                :split-promotion="splitPromotion"
                :promotion-name="promotionName"
                @split-requested="$emit('split-requested')"
                @save-button-clicked="$emit('save-button-clicked', $event)"
            />
        </div>
        <v-overlay
            v-if="showPromotionOverlay"
            class="promotion-viewer__overlay"
            :style="gridOverlayStyle(index)"
            :absolute="true"
            :value="true"
            :opacity="0.3"
            @click.native="onPromotionClick(promotionNamespace)"
        />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { get, isEmpty, isNil } from 'lodash';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import {
    enablePromotionPermalink,
    canEditPromotion,
    canEditParkingLotPromotion,
} from '@enums/feature-flags';
import namespaces from '@enums/namespaces';
import { isCategoryOrStoreWidePromotion } from '@sharedModules/promotion-utils';
import getPromotionFields from '../config/promotions-viewer-fields';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import activeComponentMixin from '@/js/mixins/active-component';
import navigation from '@/js/navigation';
import uxEvents from '@enums/ux-events';

export default {
    mixins: [configDrivenGridComponentMixin, activeComponentMixin('promotion')],
    props: {
        promotion: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
        scenario: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
        subCampaign: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        hasOpenedPromotionsAbove: {
            type: Boolean,
            default: false,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        isInDialog: {
            type: Boolean,
            default: false,
        },
        splitPromotion: {
            type: Boolean,
            default: false,
        },
        promotionName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showPromoViewer: true,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('promotions', ['selectedPromotionId']),
        ...mapState('subCampaigns', ['selectedSubCampaignId']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('tagMetadata', ['getTagOptions']),
        ...mapGetters('promotions', [
            'getStagingAreaPromotionById',
            'selectedPromotion',
            'getPromotionById',
            'entityWorkflowStateById',
            'getParentPromotionsKeyedById',
            'getPromotionNotifications',
        ]),

        fields() {
            return getPromotionFields(this.isParkingLot || this.isSubCampaignAllocation);
        },

        subCampaignIdGetter() {
            return this.isSubCampaignAllocation ? this.subCampaign._id : this.selectedSubCampaignId;
        },

        // when creating new promotion in sub-campaign-allocation we want to use sub-campaign passed as a prop
        subCampaignToUse() {
            return isEmpty(this.subCampaign) ? this.selectedSubCampaign : this.subCampaign;
        },

        isParkingLotOrSubCampaignTab() {
            return this.isParkingLot || this.isSubCampaignAllocation;
        },

        updatedPromotion() {
            // as we don't use selectedPromotion from store inside <promotion-viewer/> child components,
            // we need to be sure that all the fields are updated from the store in edit mode
            return this.isNewPromotion ? this.promotion : this.getPromotionById(this.promotion._id);
        },

        currentPromotion() {
            // We do not store execution details in the staging area, take them from the selected promotion object.
            // Execution details are updating on BE after execution api callback is called
            const execution =
                this.promotion._id === this.selectedPromotionId
                    ? (this.selectedPromotion || {}).execution
                    : this.promotion.execution;

            // copying promotion from stagingArea and retain other fields
            return {
                ...this.promotion,
                ...this.getStagingAreaPromotionById(this.promotionNamespace),
                execution: { ...execution },
            };
        },

        getColourClass() {
            const { effectivenessRating } = this.currentPromotion;
            if (effectivenessRating) {
                return `promotion-viewer__border-colour--${effectivenessRating}`;
            }

            return '';
        },

        isSelected() {
            return this.currentPromotion._id === this.selectedPromotionId;
        },

        isNewPromotion() {
            return isEmpty(this.promotion);
        },
        hasParentSubCampaign() {
            return !isNil(this.scenario.subCampaignId);
        },
        tagsOptions() {
            if (this.hasParentSubCampaign) {
                return this.subCampaignToUse.tags;
            }
            return this.getTagOptions;
        },

        isAlwaysExpanded() {
            return this.isParkingLotOrSubCampaignTab;
        },

        promotionNamespace() {
            return this.isNewPromotion ? namespaces.default : this.promotion._id;
        },

        isSavePromotionDisabled() {
            return (
                this.$refs.promotionMaintenanceWrapper.isSavePromotionDisabled ||
                this.isPastPromotions
            );
        },

        promotionEditingState() {
            if (this.isPastPromotions) {
                return {
                    disabled: true,
                    reason: this.$t('parkingLot.pastPromotions.cannotEdit'),
                };
            }
            if (this.isParkingLot) {
                return {
                    disabled: !this.toggleLogic[canEditParkingLotPromotion],
                    reason: !this.toggleLogic[canEditParkingLotPromotion]
                        ? this.$t('planning.promotionsViewer.noPermissionToEdit')
                        : null,
                };
            }

            if (!this.toggleLogic[canEditPromotion]) {
                return {
                    disabled: true,
                    reason: this.$t('planning.promotionsViewer.noPermissionToEdit'),
                };
            }

            return createFeatureAwareFactory(this.toggleLogic).isPromotionEditingDisabled(
                {
                    promotionId: this.promotion._id,
                    subCampaign: this.subCampaignToUse,
                    getPromotionById: this.getPromotionById,
                    entityWorkflowStateById: this.entityWorkflowStateById,
                    getParentPromotionsKeyedById: this.getParentPromotionsKeyedById,
                    getPromotionNotifications: this.getPromotionNotifications,
                },
                this.$moment
            );
        },

        ghostFadedClass() {
            return this.promotion.isGhost && !this.isActive ? 'promotion--faded' : '';
        },

        notificationsAreBeingApplied() {
            return this.scenario.applyingNotifications || this.promotion.applyingNotifications;
        },

        getEmptyProductsMessage() {
            return isCategoryOrStoreWidePromotion(this.promotion)
                ? 'planning.promotionsViewer.multipleProducts'
                : '';
        },

        getEmptySuppliersMessage() {
            return isCategoryOrStoreWidePromotion(this.promotion)
                ? 'planning.promotionsViewer.multipleSuppliers'
                : '';
        },

        showPromotionOverlay() {
            return (
                (this.currentPromotion.execution && this.currentPromotion.execution.executionId) ||
                this.notificationsAreBeingApplied ||
                this.currentPromotion.splitInProgress
            );
        },
    },
    mounted() {
        this.$emit('mounted');
    },
    methods: {
        ...mapActions('promotions', ['setSelectedPromotion']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        getPromotionField(promotion, field) {
            return get(promotion, field);
        },

        onPromotionClick(promotionId) {
            // routing navigation should be disabled for parking lot and sub-campaign allocation
            if (this.isParkingLotOrSubCampaignTab) return;

            if (this.selectedPromotion && this.selectedPromotion._id === promotionId) {
                // promotion will be collapsed, need to go to scenario view
                navigation.toPromotionsViewerView();
                this.setSelectedPromotion({ promotionId: null });
                this.setUnsavedPromotion({
                    namespace: promotionId,
                    tab: 'all',
                    value: false,
                });
            } else {
                // promotion will be expanded, need to go to promotion view
                if (this.toggleLogic[enablePromotionPermalink]) {
                    navigation.toPromotionView({ promotionId });
                } else {
                    this.setSelectedPromotion({ promotionId });
                }

                this.$nextTick(() => {
                    const el = get(this.$refs, `promotion_${promotionId}.0.$children.0.$el`);

                    if (isNil(el)) return;

                    this.$scrollTo(el, 500, { offset: -75 });
                });
            }
        },

        savePromotion() {
            return this.$refs.promotionMaintenanceWrapper.savePromotion();
        },

        gridOverlayStyle(index) {
            const numberOfRowsInMinimizedPromo = 1;
            const numberOfRowsInOpenedPromo = 3;
            let rowStart = index * numberOfRowsInMinimizedPromo + (this.isInDialog ? 1 : 2);
            if (this.hasOpenedPromotionsAbove) {
                rowStart += 2;
            }
            const style = {
                'grid-row': `${rowStart} / ${rowStart +
                    (this.isActive ? numberOfRowsInOpenedPromo : numberOfRowsInMinimizedPromo)}`,
            };

            if (!this.isInDialog) {
                style['margin-left'] = '1.5rem';
                style['margin-top'] = '0.5rem';
            }

            return style;
        },
    },
    events: {
        onAllNotificationsAccepted({ scenarioId }) {
            // Navigate back to scenario view and close any open promo
            // in case the UI is still in the same scenario
            if (scenarioId === this.scenario._id) {
                navigation.toScenarioView({ scenarioId });
                this.setSelectedPromotion({ promotionId: null });
            }
        },
        onResetPromotionViewerDialog({ currentTab }) {
            this.showPromoViewer = false;
            this.$nextTick(() => {
                this.showPromoViewer = true;
                this.$nextTick(() => {
                    this.globalEmit(uxEvents.navigateToPlannerTab, { tab: currentTab });
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$grid-padding: 0.5rem;

.promotion-viewer {
    font-size: 1.2rem;
    display: contents;
    position: relative;

    &__data {
        @include flex-column;
        padding: 0.3rem 1rem;
        min-height: 5rem;
        height: 100%;
        background-color: $promo-white;
        border-top: $promo-product-badge 0.2rem solid;
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 1.2rem;
        color: $text-colour;
        letter-spacing: 0;

        &--expanded {
            border-bottom: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__border-colour {
        @include promo-rag-colours(border-top-color);
    }

    &__grid-cell {
        grid-column: span 1;

        &--main-content {
            padding-top: $grid-padding;

            background-color: $promo-grey-3;
        }

        &--sidebar {
            z-index: $promo-details-z-index;
            background-color: $promo-white;

            .promotion-viewer__data {
                border-bottom: none;
            }
        }
    }

    &--first-item {
        margin-left: 1.5rem;
        grid-column-start: 1;
    }

    &__maintenance-pane {
        @include span-full-row;
    }

    &__extra-options {
        background-color: $promo-white;
        border-bottom: $border-shadow 0.1rem solid;

        &.expanded-promotion {
            border-bottom: unset;
        }

        &:hover {
            cursor: pointer;
        }

        &.extra-options {
            &__tag-selector {
                grid-column-start: 1;
                grid-column-end: -4;
                display: flex;
                align-items: flex-end;
            }
        }
    }
}

.promotion {
    &--faded {
        .promotion-viewer__data,
        .promotion-viewer__extra-options {
            opacity: 0.65;
        }
    }
}

.promotion-viewer__overlay {
    z-index: $promotion-overlay-z-index !important;
    grid-column-start: 1;
    grid-column-end: -1;
}
</style>
