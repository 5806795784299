/* eslint-disable no-underscore-dangle */
import { get, isObject, isDate, isNil, isArray, size } from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';

let currentRefreshTokenRequest = null;

const encodeAxiosParam = value => {
    let v = value;
    if (isDate(value)) {
        v = value.toISOString();
    } else if (isObject(value)) {
        v = JSON.stringify(value);
    }
    return encodeURIComponent(v);
};

const interceptors = {
    csrfToken: (context, request) => {
        request.headers['x-csrf-token'] = context.csrfToken;
        return request;
    },
    timezone: (store, request) => {
        const logging = get(store.state, 'context.generalConfig.dateFormats.logging', null);
        // collect minutes of difference between current time and UTC
        // different is UTC - now, so we flip to sum based on UTC later
        request.headers['x-timezone-offset'] = 0 - new Date().getTimezoneOffset();
        request.headers['x-usertime'] = moment().format(logging);
        return request;
    },
    responseError: (store, router, error) => {
        // Was this cancelled by our code?
        if (axios.isCancel(error)) {
            // If so, pass through the error
            return Promise.reject(error);
        }

        // Unauthenticated?
        if (error.response.status === 401) {
            // ensure it is not because of a hardcoded login attempt with invalid credentials
            if (error.config.url.endsWith('/login')) return Promise.reject(error);

            // Try first refreshing the token
            const shouldAttemptRefresh =
                error.response.data && error.response.data.attemptTokenRefresh;
            if (
                shouldAttemptRefresh &&
                !error.config.url.endsWith('/api/token/refresh') &&
                !error.config._triedRefreshToken &&
                !error.config._refreshFailed
            ) {
                // Do not attempt multiple requests to refresh in parallel, or they would fail since the last ones will attempt to refresh outdated tokens
                error.config._triedRefreshToken = true;
                if (!currentRefreshTokenRequest) {
                    currentRefreshTokenRequest = store
                        .dispatch('context/refreshUserContext')
                        .then(() => {
                            currentRefreshTokenRequest = null;
                        })
                        .catch(() => {
                            currentRefreshTokenRequest = null;
                            // if the token refresh fails, let the error handler decide what to do with the original request, but mark the request as having failed to refresh
                            error.config._refreshFailed = true;
                            return interceptors.responseError(store, router, error);
                        });
                }

                // Once the token has been successfully refreshed, retry the original request, otherwise just pass back the result of the error handler
                // Any errors with this request would go through the interceptor for the new request, as part of the axios.request() call
                return currentRefreshTokenRequest.then(res => {
                    return !error.config._refreshFailed
                        ? axios.request(error.config)
                        : Promise.resolve(res);
                });
            }

            // Were we loading the /user-context as part of application startup?
            // If we couldnt load it even after trying refreshing the auth tokens, return empty context and let the bootstrap process to finish
            // The router navigation guard will navigate to the login page (since there will be no profile in the context)
            if (error.config.url.endsWith('/user-context')) {
                return Promise.resolve({ data: null });
            }

            // User needs to authenticate, navigate to the login page
            router.replace({ path: '/login', query: { returnPath: router.currentRoute.path } });

            return Promise.reject(error);
        }

        // 403: user is logged in and has no permission to access a resource
        // 477: modsecurity rules fail
        if ([403, 477].includes(error.response.status)) {
            router.replace({ path: `/error/${error.response.status}` });
        }

        return Promise.reject(error);
    },
    paramsSerializer: params => {
        return Object.entries(params)
            .reduce((currentParams, [key, value]) => {
                if (!isNil(value) && (isArray(value) ? !!size(value) : true)) {
                    if (Array.isArray(value)) {
                        const arrayValues = value.map(v => `${key}[]=${encodeAxiosParam(v)}`);
                        return currentParams.concat(arrayValues);
                    }
                    currentParams.push(`${key}=${encodeAxiosParam(value)}`);
                }
                return currentParams;
            }, [])
            .join('&');
    },
};

const install = (Vue, options) => {
    axios.interceptors.request.use(interceptors.csrfToken.bind(null, options.store.state.context));
    axios.interceptors.request.use(interceptors.timezone.bind(null, options.store));
    axios.interceptors.request.use(config => {
        config.paramsSerializer = {
            serialize: params => interceptors.paramsSerializer(params),
        };
        return config;
    });
    axios.interceptors.response.use(
        null,
        interceptors.responseError.bind(null, options.store, options.router)
    );

    axios.defaults.withCredentials = true; // make sure we store/send cookies in CORS requests between frontend and backend

    Vue.prototype.$http = axios;
};

export default {
    interceptors, // Technically not needed, but will help with testing
    install,
    encodeAxiosParam,
};
