var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showUnsaveMessage
    ? _c(
        "div",
        {
          staticClass: "banner",
          class: { banner__small: _vm.small, banner__normal: !_vm.small },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("planning.promotionsViewer.unsavedPromotion")
                )
              ) +
              "\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }