<template>
    <div class="supply-grid">
        <div class="supply-grid__title">{{ $tkey('title') | toSentenceCase }}</div>
        <div class="supply-grid__errors">
            <v-messages v-if="formRef" :color="'error'" :value="formValidationError" />
        </div>
        <promo-ag-grid
            ref="agGrid"
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 95%;"
            grid-class="ag-theme-custom__supply-grid"
            dom-layout="normal"
            @grid-ready="onReady"
            @model-updated="refreshStyles"
            @cell-value-changed="onCellValueChanged"
        />
    </div>
</template>

<script>
import { pick, keyBy, cloneDeep, forEach } from 'lodash';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { getBadgeType } from '@/js/utils/products-utils';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import { toSentenceCase } from '@/js/utils/string-utils';
import AgCheckbox from '@/js/components/promo-ag-grid/ag-checkbox';
import AgTextWithTooltip from '@/js/components/promo-ag-grid/ag-text-with-tooltip';
import SupplyCurrencyInput from './supply-currency-input';
import AgProductBadge from '@/js/components/promo-ag-grid/ag-product-badge';
import AgPercentageField from '@/js/components/promo-ag-grid/ag-percentage-field';
import validators from '@/js/validators';
import { datadogLogs } from '@datadog/browser-logs';
import { supply } from '@enums/promotion-tabs';

const BG_CLASSES = {
    cellGrey: 'cell-grey',
    cellWhite: 'cell-white',
};

export default {
    localizationKey: 'planning.promotionsMaintenance.supply',
    mixins: [vuexComponentMixin],
    props: {
        formRef: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            products: [],
            gridApi: null,
            defaultColDef: {
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                suppressMenu: true, // Hide the column menu (includes filter and auto-size options)
                minWidth: 100,
            },
            gridOptions: {
                rowHeight: 33, // Specified in pixels.
                rowSelection: 'multiple',
                suppressRowTransform: true,
                rowBuffer: 240,
                frameworkComponents: {
                    SupplyCurrencyInput,
                    AgPercentageField,
                },
                columnTypes: {
                    rowSpanColumn: {
                        rowSpan: this.rowSpanGetter,
                    },
                    customBgColumn: {
                        cellClassRules: {
                            [BG_CLASSES.cellWhite]: params => {
                                const index = params.node.rowIndex;
                                return this.rowClassesMap[index] === BG_CLASSES.cellWhite;
                            },
                            [BG_CLASSES.cellGrey]: params => {
                                const index = params.node.rowIndex;
                                return this.rowClassesMap[index] !== BG_CLASSES.cellWhite;
                            },
                        },
                    },
                },
                getRowNodeId: data => {
                    // Generates a unique ID for each row. This increases the performance of data updates.
                    return `${data.productKey}::${data.supplierCaseInformationKey}`;
                },
                enableCellTextSelection: true,
            },
            rowClassesMap: {},
            // copy of this.model, need for preventing ag-grid refresh after mutations
            agGridModel: [],
        };
    },
    computed: {
        ...mapState('clientConfig', ['promotionTabs']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        promotionProductKeys() {
            return this.model.map(product => product.productKey);
        },
        modelProductKeyProductMap() {
            const result = {};
            this.agGridModel.forEach(product => {
                result[product.productKey] = product;
            });
            return result;
        },
        promoProducts() {
            // get products from products state which also in current promotion
            return this.products
                .filter(product =>
                    this.agGridModel.some(
                        modelProduct =>
                            String(modelProduct.productKey) === String(product.productKey)
                    )
                )
                .map(product => {
                    const newProduct = pick(this.modelProductKeyProductMap[product.productKey], [
                        'productKey',
                        'name',
                        'brandDescription',
                        'supplierName',
                        'packSize',
                        'packUnit',
                        'supplierCaseInformation',
                    ]);

                    if (this.isReadOnly && this.modelProductKeyProductMap[product.productKey]) {
                        newProduct.supplierCaseInformation = this.modelProductKeyProductMap[
                            product.productKey
                        ].supplierCaseInformation;
                    }
                    return newProduct;
                });
        },

        promoProductsMap() {
            return keyBy(this.promoProducts, 'productKey');
        },
        rowDataMap() {
            return this.rowData.reduce((acc, row) => {
                const { productKey } = row;
                (acc[productKey] || (acc[productKey] = [])).push(row);
                return acc;
            }, {});
        },

        rowData() {
            const result = [];
            // make row data
            this.promoProducts.forEach(product => {
                const productDefaultPart = pick(product, [
                    'productKey',
                    'name',
                    'brandDescription',
                    'supplierName',
                    'packSize',
                    'packUnit',
                ]);
                productDefaultPart.badge = getBadgeType(product);
                if ((product.supplierCaseInformation || []).length) {
                    let someSelected = false;
                    product.supplierCaseInformation
                        .sort((a, b) => a.caseSize - b.caseSize)
                        .forEach(informationItem => {
                            // getting data from model for set isSelected field
                            const modelProductCaseInformation = this.modelProductKeyProductMap[
                                product.productKey
                            ].supplierCaseInformation;
                            // if same case information exists in the model we need to set isSelected true
                            const caseInformationItem = (modelProductCaseInformation || []).find(
                                modelInformation =>
                                    modelInformation.supplierCaseInformationKey ===
                                    informationItem.supplierCaseInformationKey
                            );
                            const isSelected =
                                caseInformationItem && caseInformationItem.isSelected;
                            if (isSelected) {
                                someSelected = true;
                            }
                            const rowSpan = (product.supplierCaseInformation || []).length;

                            let volumeSplit = null;
                            const buyingPrice =
                                isSelected && caseInformationItem
                                    ? caseInformationItem.buyingPrice
                                    : null;

                            // when we have only one supplier case no need to render volumeSplit and buyingPrice
                            if (isSelected && caseInformationItem && rowSpan > 1) {
                                volumeSplit = caseInformationItem.volumeSplit;
                            }

                            result.push({
                                ...productDefaultPart,
                                // adding row span to data helps to have quick access from cell
                                rowSpan,
                                supplierCaseInformationKey:
                                    informationItem.supplierCaseInformationKey,
                                caseSupplierName: informationItem.supplierName,
                                caseSize: informationItem.caseSize,
                                caseClientSupplierKey: informationItem.clientSupplierKey,
                                isSelected,
                                volumeSplit,
                                buyingPrice,
                                someSelected,
                            });
                        });
                }
            });
            const productKeysSomeSelected = result.reduce((acc, { productKey, someSelected }) => {
                if (!acc[productKey]) {
                    acc[productKey] = false;
                }
                if (someSelected) {
                    acc[productKey] = true;
                }
                return acc;
            }, {});
            result.forEach(p => {
                p.someSelected = productKeysSomeSelected[p.productKey];
            });
            return result;
        },
        columnDefs() {
            return [
                {
                    headerName: '',
                    field: 'badge',
                    cellRendererFramework: AgProductBadge,
                    cellClass: ['product-information'],
                    maxWidth: 55,
                    type: ['rowSpanColumn', 'customBgColumn'],
                    sortable: false,
                    unSortIcon: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.productKey`)),
                    field: 'productKey',
                    // we need combine hard coded and dynamic styles
                    // for this we can use bind and setup hardcoded styles as first param of function
                    cellClass: ['product-information', 'cell-default'],
                    maxWidth: 90,
                    type: ['rowSpanColumn', 'customBgColumn'],
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.productName`)),
                    field: 'name',
                    cellClass: ['product-information', 'cell-default'],
                    maxWidth: 200,
                    type: ['rowSpanColumn', 'customBgColumn'],
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.brandName`)),
                    field: 'brandDescription',
                    cellClass: ['product-information', 'cell-default'],
                    maxWidth: 105,
                    type: ['rowSpanColumn', 'customBgColumn'],
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.mainSupplier`)),
                    field: 'supplierName',
                    cellClass: ['product-information', 'cell-default'],
                    maxWidth: 120,
                    type: ['rowSpanColumn', 'customBgColumn'],
                    cellRendererFramework: AgTextWithTooltip,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.sizeAndUnits`)),
                    valueGetter: this.getFormattedProductSizeAndUnit,
                    cellClass: ['product-information', 'text-right'],
                    maxWidth: 80,
                    type: ['rowSpanColumn', 'customBgColumn'],
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.caseSize`)),
                    field: 'caseSize',
                    cellClass: ['product-information', 'case-size-cell', 'text-right'],
                    type: ['customBgColumn'],
                    maxWidth: 70,
                    sortable: false,
                    unSortIcon: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.supplierKey`)),
                    field: 'caseClientSupplierKey',
                    cellClass: ['product-information'],
                    type: ['customBgColumn'],
                    maxWidth: 80,
                    sortable: false,
                    unSortIcon: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.supplierOptions`)),
                    field: 'caseSupplierName',
                    cellClass: ['product-information'],
                    type: ['customBgColumn'],
                    maxWidth: 90,
                    sortable: false,
                    unSortIcon: false,
                    cellRendererFramework: AgTextWithTooltip,
                },
                {
                    headerName: '',
                    field: 'isSelected',
                    cellRendererFramework: AgCheckbox,
                    cellRendererParams: {
                        pathToData: 'data.isSelected',
                    },
                    cellClass: ['product-information', 'flex-cell'],
                    type: ['customBgColumn'],
                    maxWidth: 35,
                    sortable: false,
                    unSortIcon: false,
                    filterParams: {
                        valueFormatter: params => {
                            return params.value === 'true'
                                ? this.$tkey(`filters.someSelected`)
                                : this.$tkey(`filters.noneSelected`);
                        },
                        refreshValuesOnOpen: true,
                    },
                    valueGetter: params => {
                        return params.data.someSelected;
                    },
                    filter: 'agSetColumnFilter',
                    suppressMenu: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.caseBuyingPrice`)),
                    field: 'buyingPrice',
                    cellRendererFramework: SupplyCurrencyInput,
                    cellRendererParams: {
                        isDisabled: params => !params.isSelected,
                        cellClass: 'currency-cell-rewrite',
                        getValidationRules: params => {
                            if (!params.data.isSelected) {
                                return [];
                            }

                            return [
                                {
                                    validator: validators.minValue,
                                    params: [0],
                                    message: toSentenceCase(
                                        this.$t(`validation.supply.buyingPriceMin`)
                                    ),
                                },
                            ];
                        },
                    },
                    cellClass: ['product-information', 'overflow-visible'],
                    type: ['customBgColumn'],
                    maxWidth: 100,
                    sortable: false,
                    unSortIcon: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`headers.volumeSplit`)),
                    field: 'volumeSplit',
                    cellClass: ['product-information', 'overflow-visible'],
                    cellClassRules: {
                        'hide-value': params => params.data.rowSpan === 1,
                    },
                    type: ['customBgColumn'],
                    cellRendererSelector: params => {
                        if (params.data.rowSpan === 1) {
                            return { cellRendererFunc: () => '' };
                        }
                        return { component: 'AgPercentageField' };
                    },
                    cellRendererParams: {
                        isDisabled: params => !params.isSelected,
                        plainValue: true,
                        cellPlaceholder: '0',
                        cellClass: 'percentage-cell-rewrite',
                        getValidationRules: params => {
                            if (!params.data.isSelected) {
                                return [];
                            }
                            return [
                                {
                                    validator: validators.required,
                                    message: toSentenceCase(
                                        this.$t(`validation.supply.volumeSplitRequired`)
                                    ),
                                },
                                {
                                    validator: validators.greaterThanZero,
                                    message: toSentenceCase(
                                        this.$t(`validation.supply.volumeSplitMin`)
                                    ),
                                },
                                {
                                    validator: () => {
                                        if (params.data.rowSpan === 1) {
                                            return true;
                                        }
                                        const { productKey } = params.data;
                                        const productRows = this.rowDataMap[productKey];
                                        const message = toSentenceCase(
                                            this.$t(`validation.supply.volumeSplitSum`)
                                        );

                                        const filteredRows = productRows.filter(
                                            row => row.isSelected
                                        );
                                        if (filteredRows.length === 0) {
                                            return true;
                                        }
                                        const aggregateVolumeSplit = filteredRows.reduce(
                                            (acc, item) => acc + item.volumeSplit,
                                            0
                                        );
                                        return aggregateVolumeSplit === 100 || message;
                                    },
                                },
                            ];
                        },
                    },
                    maxWidth: 100,
                    sortable: false,
                    unSortIcon: false,
                },
            ];
        },
        formValidationError() {
            const inputs = this.formRef.inputs;
            const errorMessages = inputs.flatMap(input => input.errorBucket);
            // Just return the first error if there is one so the user knows what to address first.
            return errorMessages.slice(0, 1);
        },
    },
    watch: {
        // Need to get new products definition after we added them to promotion
        async promotionProductKeys() {
            await this.fetchPromotionProducts();
        },
    },
    async mounted() {
        await this.fetchPromotionProducts();
    },
    beforeDestroy() {
        if (!this.promotionTabs.supply.cacheDOM && this.gridOptions.api) {
            // force-clear any references pointing to the grid when it gets removed from the DOM
            this.gridOptions.api.cleanDownReferencesToAvoidMemoryLeakInCaseApplicationIsKeepingReferenceToDestroyedGrid();
        }
    },
    methods: {
        ...mapActions('products', ['fetchProducts']),
        ...mapActions('suppliers', ['fetchSCIByProductKeys']),
        ...mapActions('promotions', ['setStagingAreaField', 'setStagingAreaFields']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        onReady(params) {
            this.gridApi = params.api;
        },

        async fetchPromotionProducts() {
            const promo = this.getStagingAreaPromotionById(this.namespace);
            const { startDate, endDate } = promo;
            const suppliers = await this.fetchSCIByProductKeys({
                params: {
                    productKeys: this.promotionProductKeys,
                    startDate,
                    endDate,
                },
            });

            const suppliersByProductKey = (suppliers || []).reduce((acc, supplier) => {
                if (acc[supplier.productKey]) {
                    acc[supplier.productKey].push(supplier);
                } else {
                    acc[supplier.productKey] = [supplier];
                }
                return acc;
            }, {});
            
            if (suppliers && suppliers.length) {
                this.products = [];
            }

            forEach(suppliersByProductKey, (value, key) =>
                this.products.push({
                    productKey: key,
                    supplierCaseInformation: value,
                })
            );
            this.agGridModel = cloneDeep(this.model);
        },
        /**
         * Get row span value for each column
         *
         * @param params
         * @returns {number|*}
         */
        rowSpanGetter(params) {
            // when we use row span in ag-grid it just hide rows below using CSS
            // in dataRow we have a couple of rows for each product with the same product information
            // and different supplier case information
            // for first product row we need to set rowspan value equal to count of supplier cases options
            // other rows need to have rowspan = 1 to be hidden by first product row
            const { rowsToDisplay } = params.api.getModel();
            const index = params.node.childIndex;
            // if cell in first row just return rowSpan from data as it always first product row
            if (index === 0) {
                return params.data.rowSpan;
            }
            // cells from last row also have row span 1
            // it can be row for product with one cases option in other way it
            // will be hidden by upper product row
            if (index === rowsToDisplay.length - 1) {
                return 1;
            }

            const prevRow = rowsToDisplay[index - 1];
            // check that prev row is for the same product
            // it means that this row have to hidden by first product row
            if (prevRow.data.productKey === params.data.productKey) {
                return 1;
            }
            // previous row was for another product
            // it means this is the first row for new product
            return params.data.rowSpan;
        },
        generateRowClassesMap(rowsToDisplay) {
            rowsToDisplay.forEach(node => {
                const { rowIndex } = node;
                const prevNode = rowIndex ? rowsToDisplay[rowIndex - 1] : null;
                // start from grey
                if (rowIndex === 0) {
                    this.rowClassesMap[rowIndex] = BG_CLASSES.cellGrey;
                    return;
                }
                // copy class from the prev row for same product
                if (prevNode.data.productKey === node.data.productKey) {
                    this.rowClassesMap[rowIndex] = this.rowClassesMap[rowIndex - 1];
                } else {
                    // change color if product was changed
                    this.rowClassesMap[rowIndex] =
                        this.rowClassesMap[rowIndex - 1] === BG_CLASSES.cellGrey
                            ? BG_CLASSES.cellWhite
                            : BG_CLASSES.cellGrey;
                }
            });
        },
        refreshStyles(event) {
            const { rowsToDisplay } = event.api.getModel();
            if (rowsToDisplay && rowsToDisplay.length) {
                this.generateRowClassesMap(rowsToDisplay);
                // we can use refreshCells for reset column styles after sorting and model changes
                event.api.refreshCells({ force: true });
            }
        },
        getFormattedProductSizeAndUnit(params) {
            if (params.data.packSize !== undefined && params.data.packUnit) {
                return `${params.data.packSize}${params.data.packUnit}`;
            }
        },
        onInputsChanged(params) {
            const productIndex = this.model.findIndex(
                item => item.productKey === params.data.productKey
            );
            const product = this.model[productIndex];
            const supplierCaseInformation = cloneDeep(product.supplierCaseInformation);
            const caseInformationIndex = supplierCaseInformation.findIndex(
                item => item.supplierCaseInformationKey === params.data.supplierCaseInformationKey
            );
            if (caseInformationIndex !== -1) {
                const field = params.colDef.field;
                supplierCaseInformation[caseInformationIndex][field] = params.newValue;
                this.validateForm();
                this.setStagingAreaField({
                    namespace: this.namespace,
                    fieldPath: `products[${productIndex}]`,
                    fieldName: 'supplierCaseInformation',
                    value: supplierCaseInformation,
                    skipResetForecastingForInvalid: true,
                });
                this.setUnsavedPromotion({ namespace: this.namespace, tab: supply, value: true });
            }
        },
        updateProductKeyRowsForSomeSelected({ productKey, caseInfoRemoved }) {
            const gridNodes = [];
            this.gridApi.forEachNode(node => gridNodes.push(node.data));
            const productsToUpdate = gridNodes.filter(row => productKey === row.productKey);
            const countPreviouslySelected = productsToUpdate.filter(({ isSelected }) => isSelected)
                .length;
            if (caseInfoRemoved) {
                if (countPreviouslySelected === 0) {
                    productsToUpdate.forEach(prod => {
                        prod.someSelected = false;
                    });
                }
            } else {
                productsToUpdate.forEach(prod => {
                    prod.someSelected = true;
                });
            }
            this.gridApi.applyTransaction({ update: productsToUpdate });
            this.gridApi.onFilterChanged();
        },
        onCellValueChanged(event) {
            if (event.colDef.field === 'isSelected') {
                const { data } = event;
                const productIndex = this.model.findIndex(
                    item => item.productKey === data.productKey
                );
                const product = this.model[productIndex];
                const supplierCaseInformation = cloneDeep(product.supplierCaseInformation || []);
                let caseInfoRemoved = false;
                if (data.isSelected) {
                    const isSelectedCount = this.rowDataMap[product.productKey].filter(
                        dataItem => dataItem.isSelected
                    ).length;
                    datadogLogs.logger.info(
                        `User added sci for promotion ${this.namespace} and product: ${
                            product.productKey
                        }`
                    );
                    supplierCaseInformation.forEach(caseInfo => {
                        if (
                            data.supplierCaseInformationKey === caseInfo.supplierCaseInformationKey
                        ) {
                            caseInfo.isSelected = true;
                            caseInfo.volumeSplit =
                                data.rowSpan > 1 || isSelectedCount > 1 ? data.volumeSplit : 100;
                            caseInfo.buyingPrice = data.buyingPrice;
                        }
                    });
                    if (isSelectedCount === 1) {
                        event.node.setDataValue('volumeSplit', 100);
                    }
                } else {
                    const caseInformationIndex = supplierCaseInformation.findIndex(
                        item => item.supplierCaseInformationKey === data.supplierCaseInformationKey
                    );
                    datadogLogs.logger.info(
                        `User removed sci for promotion ${this.namespace} and product: ${
                            product.productKey
                        }`
                    );
                    supplierCaseInformation[caseInformationIndex].isSelected = false;
                    event.node.setDataValue('buyingPrice', null);
                    event.node.setDataValue('volumeSplit', 0);
                    caseInfoRemoved = true;
                }
                // need refresh validators for cells
                event.api.refreshCells({ force: true });
                this.updateProductKeyRowsForSomeSelected({
                    productKey: product.productKey,
                    caseInfoRemoved,
                });
                // do form validation for getting proper state in setStagingAreaField action
                this.validateForm();
                this.setStagingAreaField({
                    namespace: this.namespace,
                    fieldPath: `products[${productIndex}]`,
                    fieldName: 'supplierCaseInformation',
                    value: supplierCaseInformation,
                    skipResetForecastingForInvalid: true,
                });
                this.setUnsavedPromotion({ namespace: this.namespace, tab: supply, value: true });
            } else {
                this.onInputsChanged(event);
            }
        },
        validateForm() {
            if (this.formRef) {
                this.formRef.validate();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.supply-grid {
    ::v-deep {
        .currency-cell-rewrite,
        .percentage-cell-rewrite {
            margin-top: 0.5rem;
            height: 2rem;

            .v-input__slot {
                height: 2rem;
            }
            .vuex-currency-input__currency-symbol,
            .vuex-percentage-input__percentage-symbol {
                background: transparent;
                color: $promo-black;
                height: 2rem;
            }
            .v-text-field__details {
                position: absolute;
                z-index: 9;
                bottom: -0.8rem;
                left: 0.8rem;
                // uncomment this in case need to show errors near the inputs
                display: none;
            }
        }

        .currency-cell-rewrite {
            .v-text-field__details {
                left: -4rem;
            }
        }

        .flex-cell {
            @include flex-center;
        }

        .ag-cell {
            line-height: 3rem;
            background: $promo-table-white-bg-colour;

            .ag-cell-wrapper {
                display: block;
            }
        }

        .cell-default {
            background: $promo-table-white-bg-colour;
        }

        .cell-grey {
            background: $promo-table-blue-bg-colour;
        }

        .cell-white {
            background: $promo-table-white-bg-colour;
        }

        .case-size-cell {
            border-left: 0.1rem solid $promo-grey-dark;
            border-right: 0.1rem solid $promo-grey;
        }

        .text-right {
            text-align: right;
            padding-right: 0.8rem;
        }

        .hide-value {
            .ag-cell-value {
                display: none;
            }
        }
    }

    width: 100%;
    height: 75rem;
    border-bottom: solid;
    padding-bottom: 3.5rem;
    border-bottom-width: 0.75rem !important;
    border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;

    &__title {
        padding: 1rem;
        font-weight: bold;
    }
    &__errors {
        padding: 1rem;
    }
}
.product-case-size {
    border-left: 0.1rem solid;
}
</style>
