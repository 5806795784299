'use strict';

/**
 * Enum definition file for UX events that are used by globalEmit or otherwise
 *
 * @module data/enums/ux-events
 */
module.exports = {
    promoResourceDocumentCreated: 'promo-resource-document-created',
    candidateRemovedFromPromotion: 'candidate-removed-from-promotion',
    lastCandidateRemovedFromPromotion: 'last-candidate-removed-from-promotion',
    promotionSaved: 'promotion-saved',
    candidateAddedToPromotion: 'candidate-added-to-promotion',
    promotionCreated: 'promotion-created',
    promotionDeleted: 'promotion-deleted',
    promotionUpdated: 'promotion-updated',
    promotionsUpdated: 'promotions-updated',
    promotionUpdatedForForecasting: 'promotion-updated-for-forecasting',
    promotionUpdatedForUpdatingPrices: 'promotion-updated-for-updating-prices',
    promotionUpdatedForPromoPriceRecalculation: 'promotion-updated-for-promo-price-recalculation',
    promotionFundingUpdatedForForecasting: 'promotion-updated-for-funding-forecasting',
    promotionProductsUpdated: 'promotion-products-updated',
    promotionProductsUpdatedForGridRefresh: 'promotion-products-updated-for-grid-refresh',
    promotionPricesUpdated: 'promotion-prices-updated',
    promotionForecasted: 'promotion-forecasted',
    promotionForecastedAndSaved: 'promotion-forecasted-and-saved',
    promotionIsInvalid: 'promotion-is-invalid',
    promotionRateCardsUpdated: 'promotion-rate-cards-updated',
    setPromotionProduct: 'set-promotion-product',
    forecastAndSavePromotion: 'forecast-and-save-promotion',
    logout: 'logout',
    campaignDeleted: 'campaign-deleted',
    subCampaignDeleted: 'sub-campaign-deleted',
    subCampaignUpdated: 'sub-campaign-updated',
    scenarioDeleted: 'scenario-deleted',
    openProductGroup: 'open-product-group',
    productOfferGroupChange: 'product-offer-group-change',
    parkingLotPromoSaveFailed: 'parking-lot-promo-save-failed',
    resetPromotionViewerDialog: 'reset-promotion-viewer-dialog',
    navigateToPlannerTab: 'navigate-to-planner-tab',

    weeklyCampaignScroll: 'ux-weekly-view-boundary-passed',

    supplierCommitmentUpdated: 'supplier-commitment-updated',
    rateCardUpdated: 'rate-card-updated',
    variableFundingUpdated: 'variable-funding-updated',
    parkingLotSaved: 'parking-lot-saved',
    parkingLotCreated: 'parking-lot-created',
    updateMaintenanceAreaValidation: 'update-maintenance-area-validation',
    updatePromotionMechanicDescription: 'update-promotion-mechanic-description',
    allNotificationsAccepted: 'all-notifications-accepted',

    toggleFundingView: 'toggle-funding-view',
};
