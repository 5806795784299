import { get } from 'lodash';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import {
    toSentenceCase,
    suffixWithColon,
    toAllUppercaseCase,
    toCamelCase,
} from '../utils/string-utils';
import i18n from '@/js/vue-i18n';
import store from '@/js/store';

const formatNumber = (format, number) => {
    const toggleLogic = get(store.state, 'clientConfig.toggleLogic');
    const featureAwareFactory = createFeatureAwareFactory(toggleLogic);
    return featureAwareFactory.getFormatNumberFunction()(format, number);
};

const formatNumberShorthand = (format, number) => {
    return i18n.n(format, number);
};

const numbersFilters = [
    { name: 'countNumber', formatter: i18n.n },
    { name: 'efficiencyPercentage', formatter: i18n.n },
    { name: 'marginPercentage', formatter: i18n.n },
    { name: 'forecastedVolume', formatter: formatNumber },
    { name: 'forecastedIncrementalTraffic', formatter: formatNumber },
    { name: 'lumpFundingTotal', formatter: formatNumber },
    { name: 'forecastedSales', formatter: formatNumber },
    { name: 'forecastedMargin', formatter: formatNumber },
    { name: 'totalSales', formatter: formatNumber },
    { name: 'totalMargin', formatter: formatNumber },
    { name: 'numberShorthand', formatter: formatNumberShorthand },
    { name: 'currencyShorthand', formatter: formatNumberShorthand },
    { name: 'currency', formatter: formatNumber },
    { name: 'currencyNoLabel', formatter: formatNumber },
    { name: 'currencyNoLabelPadded', formatter: formatNumber },
    { name: 'currencyNoLabelPadded3dp', formatter: formatNumber },
    { name: 'percentageWith1Decimal', formatter: formatNumber },
    { name: 'percentageWithDecimals', formatter: formatNumber },
];

export default Vue => {
    Vue.filter('toSentenceCase', toSentenceCase);
    Vue.filter('suffixWithColon', suffixWithColon);
    Vue.filter('toAllUppercaseCase', toAllUppercaseCase);
    Vue.filter('toCamelCase', toCamelCase);
    numbersFilters.forEach(filter => {
        Vue.filter(filter.name, num => filter.formatter(`numbers.default.${filter.name}`, num));
    });
};
