<template>
    <div
        v-if="showUnsaveMessage"
        :class="{ banner__small: small, banner__normal: !small }"
        class="banner"
    >
        {{ $t('planning.promotionsViewer.unsavedPromotion') | toSentenceCase }}
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { get } from 'lodash';

export default {
    props: {
        promotionId: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState('promotions', ['unsavedPromotion']),

        showUnsaveMessage() {
            const tabValues = Object.values(get(this.unsavedPromotion, this.promotionId, {}));
            return tabValues.some(tabValue => tabValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.banner {
    margin: 1rem;
    background-color: $promo-rag-2;
    border: 0.1rem solid $promo-orange-dark;
    border-radius: 0.5rem;
    font-weight: normal;
    &__small {
        height: 2rem;
        padding: 0 1rem 0 1rem;
    }
    &__normal {
        height: 3rem;
        padding: 0.5rem 1rem;
    }
}
</style>
