import axios from 'axios';
import { every, get, merge, values } from 'lodash';
import executionApiNotifications from '@enums/execution-api-notifications';
import promotionApiNotifications from '@enums/promotion-api-notifications';
import applyNotificationsNotifications from '@enums/apply-notifications-notifications';
import exportNotifications from '@enums/export-notifications';
import resources from '@enums/resources';
import notificationTypes from '@enums/notification-types';
import sortDirection from '@enums/sort-direction';
import notificationStatuses from '@enums/notification-statuses';
import storeMixin from '@/js/store/mixins/vuex-store';
import { sortByDate } from '@/js/utils/sort-utils';

const getInitialState = () => ({
    notifications: [],
    eventStream: null,
});

const params = {
    resource: 'notifications',
    readOnly: true,
};

const {
    mutations: { resetState },
    actions: { resetState: resetStateAction },
} = storeMixin(params);

const resourceToResourceIdMap = {
    [resources.promotions]: 'promotionId',
};

const applyNotificationsNotificationsToAction = {
    [applyNotificationsNotifications.FINISHED_APPLYING_NOTIFICATIONS_TO_PROMOTION]:
        'promotions/updatePromotionAfterNotificationsApplication',
    [applyNotificationsNotifications.FINISHED_APPLYING_NOTIFICATIONS_TO_SCENARIO]:
        'scenarios/updateScenarioAfterNotificationsApplication',
};

const store = {
    namespaced: true,

    state: getInitialState(),

    /**
     * Default getters available:
     * - getNotificationsById
     */
    getters: {
        getNotifications: state => state.notifications.filter(n => !n.notificationKey),
        getNotificationsForScenario: state => scenarioId =>
            state.notifications.filter(
                n => get(n, 'details.entityIds.scenarioId', null) === scenarioId
            ),
        landingPageNotifications: (state, getters, rooState, rootGetters) => {
            const allLandingPageNotifications = state.notifications
                // Landing page notifications will have a specified notification key
                .filter(n => n.notificationKey)
                // Add in the config based on the notification
                .map(notification => {
                    return {
                        ...notification,
                        ...rootGetters['clientConfig/notificationsConfigByKey'][
                            notification.notificationKey
                        ],
                    };
                })
                // Filter out any notifications based on the current users assigned roles
                .filter(n => {
                    const permissions = get(n, 'permissions', []);
                    if (!permissions.length) return true;
                    return every(permissions, permission =>
                        rootGetters['context/hasPermission'](permission)
                    );
                });
            // return all notifications sorted by last update date (desc)
            return [...allLandingPageNotifications].sort((a, b) =>
                sortByDate(a, b, sortDirection.descending)
            );
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setNotifications
     * - deleteNotifications
     * - updateNotifications
     * - addNotifications
     * - resetState
     */
    mutations: {
        addNotification(state, notification) {
            state.notifications.push(notification);
        },
        resetState,

        setEventStream(state, { eventStream }) {
            state.eventStream = eventStream;
        },
        upsertNotification(state, notification) {
            state.notifications = [
                ...state.notifications.filter(item => item._id !== notification._id),
                notification,
            ];
        },
        removeNotificationByResourceId(state, { resourceId, notificationKey, resource }) {
            state.notifications = state.notifications.filter(notification => {
                const entityId = get(
                    notification,
                    `details.entityIds.${resourceToResourceIdMap[resource]}`
                );
                return (
                    entityId !== resourceId ||
                    (entityId === resourceId && notification.notificationKey !== notificationKey)
                );
            });
        },
        removeNotificationById(state, { notificationId }) {
            state.notifications = state.notifications.filter(
                notification => notification._id !== notificationId
            );
        },
    },

    /**
     * Default actions available:
     * - fetchNotifications
     * - createNotifications
     * - deleteNotifications
     * - updateNotifications
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        addNotification({ commit }, { message, popupTimeout, severity }) {
            commit('addNotification', { message, popupTimeout, severity });
        },
        resetState: resetStateAction,

        fetchOpenNotifications({ dispatch }) {
            dispatch('fetchNotifications', {
                params: {
                    where: {
                        status: notificationStatuses.open,
                        // unlocked notifications ignored for performance reasons due to high load + low business value when displayed to users
                        notificationKey: { $nin: [notificationTypes.promotionUnlocked] },
                    },
                    // Don't load the changeset details until required for a specific promotion.
                    pick: ['{"details.changeset": 0}'],
                },
            });
        },

        async fetchOpenNotificationsForScenario({ dispatch }, { scenarioId }) {
            await dispatch('fetchNotifications', {
                params: {
                    where: {
                        status: notificationStatuses.open,
                        // unlocked notifications ignored for performance reasons due to high load + low business value when displayed to users
                        notificationKey: { $nin: [notificationTypes.promotionUnlocked] },
                        'details.entityIds.scenarioId': scenarioId,
                    },
                    // Don't load the changeset details until required for a specific promotion.
                    pick: ['{"details.changeset": 0}'],
                },
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: notification =>
                        get(notification, 'details.entityIds.scenarioId') === scenarioId,
                },
            });
        },

        async createNotification(_, { resourceId, notificationKey, resource }) {
            await axios.post('/api/notifications', {
                resourceId,
                notificationKey,
                resource,
            });
        },
        async closeNotification(
            { commit },
            { resourceId, notificationKey, resource, notificationId = null }
        ) {
            if (notificationId) {
                await axios.patch(`/api/notifications/close/${notificationId}`);
                commit('removeNotificationById', { notificationId });
            } else {
                await axios.patch('/api/notifications/close', {
                    resourceId,
                    resource,
                    notificationKey,
                });
                commit('removeNotificationByResourceId', { resourceId, notificationKey, resource });
            }
        },

        async handleApplyNotificationsNotifications({ dispatch }, { event, entityId, report }) {
            const action = applyNotificationsNotificationsToAction[event];
            await dispatch(
                action,
                {
                    entityId,
                    report,
                },
                { root: true }
            );
        },
        upsertNotification({ commit, dispatch }, { notification }) {
            if (notification.notificationKey === notificationTypes.parentPromotionUpdated) {
                dispatch(
                    'promotions/updateNotificationStateForPromotion',
                    { notification },
                    { root: true }
                );
            }
            commit('upsertNotification', notification);
        },

        openNotificationStream({ commit, dispatch, state, rootGetters }) {
            let reconnectFrequencySeconds = 1; // first attempt to reconnect after 1 second
            let reconnectCount = 0;
            let source;

            // reconnection handling from https://stackoverflow.com/a/54385402
            function throttle(func) {
                let timeout;
                return () => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        timeout = null;
                        func();
                    }, reconnectFrequencySeconds * 1000);
                };
            }

            // attempt reconnection every `reconnectFrequencySeconds`
            const reconnectFunc = throttle(() => {
                reconnectCount += 1;

                // Force a token refresh, as this is the main reason why eventstream connections drops
                // Only force resfresh every 5th connection attempt, to avoid refreshing the token just after another refresh
                if (reconnectCount > 1 && reconnectCount % 5 === 0) {
                    dispatch('context/refreshUserContext', {}, { root: true });
                }

                // eslint-disable-next-line no-use-before-define
                setupEventSource();

                // Double every attempt to avoid overwhelming server
                reconnectFrequencySeconds = Math.min(reconnectFrequencySeconds * 2, 15);
            });

            function setupEventSource() {
                if (state.eventStream) {
                    state.eventStream.close();
                    commit('setEventStream', { eventStream: null });
                }

                source = new EventSource(`/api/notifications/stream`, {
                    withCredentials: true,
                });

                source.addEventListener('open', () => {
                    reconnectCount = 0;
                });

                source.addEventListener('error', function() {
                    // close the source (e.g. on token timeout)
                    source.close();
                    reconnectFunc();
                });

                source.addEventListener('message', function(e) {
                    // this handler is only used by execution-api for now
                    // can be extened to support other types of messages
                    const { data: message, lastEventId } = e;

                    // blank messages are sent intermittently to keep the EventSource open
                    if (message === '') {
                        return;
                    }

                    const { result } = JSON.parse(message);

                    if (
                        lastEventId === executionApiNotifications.PROMOTION_EXECUTION &&
                        result &&
                        result.promotionId
                    ) {
                        // if promotion is already in state - update it's execution status
                        const promotion = rootGetters['promotions/getPromotionById'](
                            result.promotionId
                        );

                        if (promotion && !promotion.execution.executionId) {
                            dispatch(
                                'promotions/setPromotionExecution',
                                {
                                    promotionId: result.promotionId,
                                    executionId: result.executionId,
                                },
                                { root: true }
                            );

                            return;
                        }
                    }

                    if (
                        lastEventId === executionApiNotifications.PROMOTION_CALLBACK &&
                        result &&
                        result.result &&
                        result.result.promotionId
                    ) {
                        const promotion = rootGetters['promotions/getPromotionById'](
                            result.result.promotionId
                        );
                        // if promotion is already in state - refetch promotions - to get latest execution object
                        if (promotion) {
                            dispatch(
                                'promotions/fetchPromotionsForSelectedSubCampaign',
                                {},
                                { root: true }
                            );
                        }
                        // clear execution timeout on successfull callback
                        dispatch(
                            'execution/clearPromotionExecutionTimeout',
                            { promotionId: result.result.promotionId },
                            { root: true }
                        );
                        return;
                    }

                    if (
                        lastEventId === promotionApiNotifications.PROMOTION_SPLITTING &&
                        result &&
                        result.promotionId
                    ) {
                        // if promotion is already in state - update splitInProgress and isDeleted flags
                        const promotion = rootGetters['promotions/getPromotionById'](
                            result.promotionId
                        );

                        // if promotion is already in state - refetch promotions - to get latest execution object
                        if (promotion) {
                            dispatch(
                                'promotions/fetchPromotionsForSelectedSubCampaign',
                                {},
                                { root: true }
                            );
                        }

                        return;
                    }

                    if (
                        values(applyNotificationsNotifications).includes(lastEventId) &&
                        result &&
                        (result.promotionId || result.scenarioId)
                    ) {
                        dispatch('handleApplyNotificationsNotifications', {
                            entityId: result.promotionId || result.scenarioId,
                            event: lastEventId,
                            report: result.report,
                        });
                        return;
                    }

                    if (result && result.notificationKey) {
                        dispatch('upsertNotification', { notification: result });
                        return;
                    }

                    if (lastEventId === exportNotifications.exportLimitReached) {
                        dispatch('handleResponseNotifications', {
                            warningMessage: {
                                key: result.warningMessageKey,
                                params: result.warningMessageParams,
                            },
                        });
                    }
                });
            }

            // initial setup
            setupEventSource();
        },
    },
};

const mixinParams = {
    resource: 'notification',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
